import Session1 from "./session1.svg";
import Session2 from "./session2.svg";
import Session3 from "./session3.svg";
import Session4 from "./session4.svg";
import Session5 from "./session5.svg";
import Session6 from "./session6.svg";
import Session7 from "./session7.svg";
import Session8 from "./session8.svg";

const ColoredSessionIcons = [
  Session1,
  Session2,
  Session3,
  Session4,
  Session5,
  Session6,
  Session7,
  Session8,
];

export default ColoredSessionIcons;
